$(function() {
    let swiperCompare = new Swiper('.swiper-compare', {
        slidesPerView: 1.2,
        spaceBetween: 8,
        breakpoints: {
            960: {
                slidesPerView: 2
            },
            1201: {
                slidesPerView: 2
            },
            1580: {
                slidesPerView: 3
            }
        }
    })
    let swiperNews = new Swiper('.swiper-news', {
        slidesPerView: 1.3,
        spaceBetween: 8,
        loop: true,
        navigation: {
            nextEl: '.next-news',
            prevEl: '.prev-news',
        },
        breakpoints: {
            960: {
                slidesPerView: 2
            },
            1201: {
                slidesPerView: 3
            },
            1580: {
                slidesPerView: 4
            }
        }
    })
    let swiperReviews = new Swiper('.swiper-review', {
        slidesPerView: 1.3,
        spaceBetween: 8,
        loop: true,
        navigation: {
            nextEl: '.next-review',
            prevEl: '.prev-review',
        },
        breakpoints: {
            755: {
                slidesPerView: 1.8
            },
            960: {
                slidesPerView: 2
            },
            1201: {
                slidesPerView: 3
            },
            1580: {
                slidesPerView: 4
            }
        }
    })
    let swiperCatalog = new Swiper('.swiper-catalog', {
        slidesPerView: 1.2,
        spaceBetween: 8,
        loop: true,
        navigation: {
            nextEl: '.next-catalog',
            prevEl: '.prev-catalog',
        },
        breakpoints: {
            755: {
                slidesPerView: 1.8
            },
            960: {
                slidesPerView: 2
            },
            1201: {
                slidesPerView: 3
            },
            1580: {
                slidesPerView: 5
            }
        }
    })
    let swiperPopular = new Swiper('.swiper-popular', {
        slidesPerView: 1.2,
        spaceBetween: 8,
        loop: true,
        navigation: {
            nextEl: '.next-popular',
            prevEl: '.prev-popular',
        },
        breakpoints: {
            755: {
                slidesPerView: 1.8
            },
            960: {
                slidesPerView: 2
            },
            1201: {
                slidesPerView: 3
            },
            1580: {
                slidesPerView: 5
            }
        }
    })
    let swiperBanks = new Swiper('.swiper-banks', {
        slidesPerView: 2,
        spaceBetween: 8,
        loop: true,
        navigation: {
            nextEl: '.next-banks',
            prevEl: '.prev-banks',
        },
        breakpoints: {
            960: {
                slidesPerView: 4
            },
            1201: {
                slidesPerView: 5
            },
            1580: {
                slidesPerView: 6
            }
        }
    })

    /*  container Narrow */

    let swiperCatalogNarrow = new Swiper('.swiper-catalog-narrow', {
        slidesPerView: 4,
        spaceBetween: 8,
        loop: true,
        navigation: {
            nextEl: '.next-catalog',
            prevEl: '.prev-catalog',
        },
    })
    let swiperPopularNarrow = new Swiper('.swiper-popular-narrow', {
        slidesPerView: 4,
        spaceBetween: 8,
        loop: true,
        navigation: {
            nextEl: '.next-popular',
            prevEl: '.prev-popular',
        },
    })
    let swiperBanksNarrow = new Swiper('.swiper-banks-narrow', {
        slidesPerView: 5,
        spaceBetween: 8,
        loop: true,
        navigation: {
            nextEl: '.next-banks',
            prevEl: '.prev-banks',
        },
    })

    /**/

    let swiperBanner = new Swiper('.swiper-banner', {
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,
        autoplay: {
            delay: 15000,
        },
        pagination: {
            el: ".swiper-banner-pagination",
        },
    })

    $('.js-range').each(function(index, el) {

        let max = $(el).data('max')
        let min = $(el).data('min')

        $(el).slider({
            value: min,
            min: min,
            max: max,
            range: "min",
            slide: function(event, ui) {
                $(el).closest('.label').find(".amount").val(ui.value);
            }
        })

    })

    $('.js-select').each(function(index, el) {
        new Choices(el, {
            placeholder: true,
            searchEnabled: false,
        });
    })

    $('.js-color-change').on('click', function() {
        let img = $(this).data('image')

        $('.js-color-change').removeClass('active')
        $(this).addClass('active')

        $(this).closest('.js-color-wrapper').find('#carColor').attr('src', img)
    })

    $('[data-tooltip]').tooltip()

    var swiperDetailsThumb = new Swiper(".details-swiper-thumbs", {
        loop: true,
        spaceBetween: 8,
        slidesPerView: 4,
        freeMode: true,
        watchSlidesProgress: true,
    });
    var swiperDetails = new Swiper(".details-swiper", {
        loop: true,
        spaceBetween: 10,
        thumbs: {
            swiper: swiperDetailsThumb,
        },
    });

    $('.js-catalog').on('click', function() {
        $(this).closest('nav').toggleClass('show__model')
        return false
    })

    $('.js-menu-xs').on('click', function() {
        $(this).toggleClass('showen')
        $('.header__nav-xs-menu').toggle()
        return false
    })

    $('main, footer, header').on('click', function() {
        $('.js-menu-xs').removeClass('showen')
        $('.header__nav-xs-menu').hide()
        $('nav').removeClass('show__model')
    })

    $('.dropdown > span').on('click', function() {
        $(this).next('.dropdown-list').toggle()
    })
    $('.dropdown-item').on('click', function() {
        $(this).closest('.dropdown-list').hide()
    })

    $('.js-content-spoiler').on('click', function() {
        $(this).closest('section').find('.content__block-hide').show()
        $(this).hide()

        return false
    })

    $('.js-model-all').on('click', function() {
        $(this).prev('.models__list').find('.models__item').css('display', 'flex')
        $(this).hide()
        return false
    })

    if($(window).width() < 992) {
        $('.js-footer-spoiler').on('click', function() {
            $(this).toggleClass('showen')
        })
    }

    $('.js-show-filter').on('click', function() {
        $('.catalog__form-aside').show();
        return false;
    })

    $('.js-filter-close').on('click', function() {
        $('.catalog__form-aside').hide();
        return false;
    })
})